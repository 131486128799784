import React from "react";
import page1 from "../assets/Shubh Kakadia - Resume_page-0001.jpg";
import page2 from "../assets/Shubh Kakadia - Resume_page-0002.jpg";
import { FaFileArrowDown } from "react-icons/fa6";

export default function Resume() {
  return (
    <div id="resume">
      <div className="bg-[#333333] pb-10 pt-5">
        <div className="flex justify-center items-center">
          <div className="m-5">
            <img src={page1} alt="error" width={500} className="rounded-lg" />
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="m-5">
            <img src={page2} alt="error" width={500} className="rounded-lg" />
          </div>
        </div>
        <div className="text-[35px] text-main-blue flex justify-center items-center m-5">
          <a href="../assets/Shubh Kakadia - Resume.pdf" download>
            <FaFileArrowDown
              title="Download Resume"
              className=" icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
