import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef } from "react";
import { HashLink } from "react-router-hash-link";

export default function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const name = "<Shubh_Kakadia/>";

  return (
    <header className="flex justify-between items-center bg-main-black text-main-blue sticky top-0 h-[75px] px-8 z-[5]">
      <a className="text-main-blue text-[25px]" href="/">
        {name}
      </a>
      <nav ref={navRef} className="flex space-x-8">
        <HashLink
          to="/#home"
          className="hover:text-white links"
          onClick={showNavbar}
        >
          Home
        </HashLink>
        <HashLink
          to="/#about"
          className="hover:text-white links"
          onClick={showNavbar}
        >
          About
        </HashLink>
        <HashLink
          to="/#project"
          className="hover:text-white links"
          onClick={showNavbar}
        >
          Projects
        </HashLink>
        <HashLink
          to="/#contact"
          className="hover:text-white links"
          onClick={showNavbar}
        >
          Contact
        </HashLink>
        <a
          href="#/resume"
          className="hover:text-white links"
          onClick={showNavbar}
        >
          Resume
        </a>
        <button
          className="nav-btn nav-close-btn p-1 bg-transparent outline-none invisible opacity-0 text-[20px]"
          onClick={showNavbar}
        >
          <FaTimes />
        </button>
      </nav>
      <button
        className="nav-btn p-1 bg-transparent invisible outline-none text-[20px]"
        onClick={showNavbar}
      >
        <FaBars />
      </button>
    </header>
  );
}
