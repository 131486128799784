import React, { useEffect, useState } from "react";

const Typewriter = () => {
  const [currentText, setCurrentText] = useState("");
  const dataText = ["Shubh Kakadia"];

  const typeWriter = (text, i, fnCallback) => {
    if (i < text.length) {
      setCurrentText((prevText) => prevText + text.charAt(i));
      setTimeout(() => {
        typeWriter(text, i + 1, fnCallback);
      }, 100);
    } else if (typeof fnCallback === "function") {
      setTimeout(fnCallback, 700);
    }
  };

  const startTextAnimation = () => {
    typeWriter(dataText[0], 0, () => {
      // Animation completed
    });
  };

  useEffect(() => {
    startTextAnimation();

    // Repeat the animation every 5 seconds
    const intervalId = setInterval(() => {
      setCurrentText("");
      startTextAnimation();
    }, 5000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      setCurrentText("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1 className="text-main-blue text-[100px] type font-Raleway">{currentText}</h1>;
};

export default Typewriter;
