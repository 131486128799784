import { data } from "../assets/projects";

export default function Projects() {
  return (
    <div id="project">
      <h1 className="flex justify-center pt-20 pb-10 text-[50px]">Projects</h1>
      <div className="flex flex-wrap justify-center">
        {data.Projects.map((card, key) => (
          <div
            id={key}
            className="max-w-sm rounded-xl overflow-hidden shadow-lg card m-5"
          >
            {/* <img
              className="w-full"
              src="/img/card-top.jpg"
              alt="Sunset in the mountains"
            /> */}
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-gray-600">
                {card.Title}
              </div>
              <p className="text-gray-500 text-base">{card.Description}</p>
            </div>
            <div className="px-6 py-4 flex flex-wrap">
              {card.Tags.map((tag, key) => (
                <div id={key}>
                  <span className="inline-block bg-gray-200 rounded px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2 shadow-sm">
                    {tag[0]}
                  </span>
                  {/* <div>
                    <img src={tag[1]} alt={tag[0]} className="h-[30px]" title={tag[0]}/>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
