import HostingerIcon from "../assets/HostingerIcon.png";
import PYQT5Icon from "../assets/PYQT5Icon.png";
import EC2Icon from "../assets/EC2ICon.png";
import AWSAPIIcon from "../assets/AWS-API-Icon.png";
import DynamoDBICon from "../assets/DynamoDbIcon.png";
import S3Icon from "../assets/S3Icon.webp";
import RapidICon from "../assets/RapidIcon.png";

const ReactJsIcon =
  "https://user-images.githubusercontent.com/25181517/183897015-94a058a6-b86e-4e42-a37f-bf92061753e5.png";
const NodeJsIcon =
  "https://user-images.githubusercontent.com/25181517/183568594-85e280a7-0d7e-4d1a-9028-c8c2209e073c.png";

const MongoDBIcon =
  "https://user-images.githubusercontent.com/25181517/182884177-d48a8579-2cd0-447a-b9a6-ffc7cb02560e.png";
const ExpressJSIcon =
  "https://user-images.githubusercontent.com/25181517/183859966-a3462d8d-1bc7-4880-b353-e2cbed900ed6.png";
const BootstrapIcon =
  "https://user-images.githubusercontent.com/25181517/183898054-b3d693d4-dafb-4808-a509-bab54cf5de34.png";
const CSSIcon =
  "https://user-images.githubusercontent.com/25181517/183898674-75a4a1b1-f960-4ea9-abcb-637170a00a75.png";
const RestFullAPIIcon =
  "https://user-images.githubusercontent.com/25181517/192107858-fe19f043-c502-4009-8c47-476fc89718ad.png";
const PythonIcon =
  "https://user-images.githubusercontent.com/25181517/183423507-c056a6f9-1ba8-4312-a350-19bcbc5a8697.png";
const TailwindIcon =
  "https://user-images.githubusercontent.com/25181517/202896760-337261ed-ee92-4979-84c4-d4b829c7355d.png";

const HTMLIcon =
  "https://user-images.githubusercontent.com/25181517/192158954-f88b5814-d510-4564-b285-dff7d6400dad.png";

export const data = {
  Projects: [
    {
      img: "",
      Title: "HR Management System",
      Description:
        "MERN-Stack Employee Data Application: Utilizes ReactJS, NodeJS, MongoDB, ExpressJS for robust data management, user-friendly interfaces, secure storage, and efficient employee information handling.",
      Tags: [
        ["ReactJs", ReactJsIcon],
        ["NodeJs", NodeJsIcon],
        ["MongoDB", MongoDBIcon],
        ["ExpressJs", ExpressJSIcon],
        ["Bootstrap", BootstrapIcon],
        ["CSS", CSSIcon],
        ["RESTful APIs", RestFullAPIIcon],
      ],
    },
    {
      img: "",
      Title: "ICT Capstone Project - Pepper Robot",
      Description:
        "Progreammed Pepper Robot for our client UniSA health, The programme was responsible to aid in clinic operations, demonstrating innovative use of robotics in healthcare.",
      Tags: [["Python", PythonIcon]],
    },
    {
      img: "",
      Title: "Pathfinding Algorithm",
      Description:
        "Engineered a solution to calculate the shortest path between two points, showcasing algorithmic efficiency. It was developed with the help of AStar Algorithm,",
      Tags: [["Python", PythonIcon]],
    },
    {
      img: "",
      Title: "Portfolio Website",
      Description:
        "Designed and created a website to effectively showcase my projects and technical skills.",
      Tags: [
        ["ReactJs", ReactJsIcon],
        ["NodeJs", NodeJsIcon],
        ["Tailwind", TailwindIcon],
        ["Hostinger", HostingerIcon],
        ["CSS", CSSIcon],
      ],
    },
    {
      img: "",
      Title: "Countdown Timer Application",
      Description:
        "Countdown timer that allows user to add multiple sequencial tasks and play around with those tasks, user can create schedule and it through email.",
      Tags: [
        ["Python", PythonIcon],
        ["PYQT5", PYQT5Icon],
      ],
    },
    {
      img: "",
      Title: "Sudoku Solver",
      Description:
        "Developed a console app with a back-tracking algorithm for Sudoku puzzles, demonstrating problem-solving skills and algorithmic efficiency.",
      Tags: [["Python", PythonIcon]],
    },

    {
      img: "",
      Title: "Youtube Video Downloader",
      Description:
        "Crafted a user-friendly app for easy YouTube video downloads, focusing on accessibility, intuitive design, and efficient functionality.",
      Tags: [
        ["Python", PythonIcon],
        ["PYQT5", PYQT5Icon],
      ],
    },
    {
      img: "",
      Title: "React App Hosted",
      Description:
        "Hosted a React app on AWS, showcasing enhanced cloud computing skills and proficiency in deploying modern web applications.",
      Tags: [
        ["ReactJs", ReactJsIcon],
        ["CSS", CSSIcon],
        ["Amazon EC2", EC2Icon],
        ["Amazon API Gateway", AWSAPIIcon],
        ["Amazon DynamoDB", DynamoDBICon],
        ["Amazon S3", S3Icon],
      ],
    },
    {
      img: "",
      Title: "Weather Application",
      Description:
        "Created an intuitive app with API integration for displaying weather data, offering easy access and clear visualization.",
      Tags: [
        ["HTML", HTMLIcon],
        ["CSS", CSSIcon],
        ["RapidAPI", RapidICon],
        ["RESTful APIs", RestFullAPIIcon],
      ],
    },
  ],
};
