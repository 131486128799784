import React from "react";

export default function About() {
  return (
    <div id="about" className="about bg-[#f5f5f7] py-[50px]">
      <div className="flex justify-center">
        <h1 className="text-main-black text-[50px]">About Me</h1>
      </div>
      <div className="text-center md:p-20 p-10 text-[15px] md:text-[20px] para">
        I'm a Master's candidate in Information Technology at The University of
        South Australia, skilled in ReactJS and full-stack development. With a
        strong academic and practical background, I excel in adapting to new
        technologies and have a passion for data and visualization. I'm eager to
        contribute and grow in new professional opportunities.
      </div>
    </div>
  );
}
