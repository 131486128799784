import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Resume from "./components/Resume";
import "./components/style.css";
import Landing from "./components/Landing";
// import Overlay from "./components/Overlay";
import AWS from "aws-sdk";
import { useDispatch, useSelector } from "react-redux";
import { getip } from "./state/actions/getip";
import Overlay from "./components/Overlay";
import { motion } from "framer-motion";

function App() {
  AWS.config.update({
    region: "us-east-1",
    accessKeyId: "WIwLTzl8WAP0Ch08VpS9RhR2qDrCEi1rMxYz/u/7",
    secretAccessKey: "your-secret-access-key",
  });

  const dispatch = useDispatch();
  const user_ip = useSelector((state) => state.getip.data);

  useEffect(() => {
    if (user_ip === undefined) {
      dispatch(getip());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_ip]);

  console.log(user_ip);

  // const cursorDot = document.querySelector("[data-cursor-dot]");
  // const cursorOutline = document.querySelector("[data-cursor-outline");

  // window.addEventListener("mousemove", function (e) {
  //   const posX = e.clientX;
  //   const posY = e.clientY;
  //   cursorDot.style.left = `${posX}px`;
  //   cursorDot.style.top = `${posY}px`;
  //   // cursorOutline.style.left = `${posX}px`;
  //   // cursorOutline.style.top = `${posY}px`;

  //   cursorOutline.animate(
  //     {
  //       left: `${posX}px`,
  //       top: `${posY}px`,
  //     },
  //     { duration: 500, fill: "forwards" }
  //   );
  // });

  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateCursor = (e) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", updateCursor);

    return () => {
      window.removeEventListener("mousemove", updateCursor);
    };
  }, []);

  const variants = {
    dot: {
      x: cursorPos.x,
      y: cursorPos.y,
    },
    outline: {
      x: cursorPos.x - 10,
      y: cursorPos.y - 10,
    },
  };

  return (
    <div className="app">
      <motion.div className="cursor-dot" variants={variants} animate="dot">
      </motion.div>
      <motion.div
        className="cursor-outline"
        variants={variants}
        animate="outline"
        transition={{
          ease: "linear",
          duration: 0.3,
        }}
      >
        {/* Outline Cursor Content */}
      </motion.div>
      <link rel="icon" href="../src/assets/diamond.png" />
      <React.Fragment>
        <script
          src="https://kit.fontawesome.com/59166d771b.js"
          crossOrigin="anonymous"
        ></script>
        <script src="https://smtpjs.com/v3/smtp.js"></script>
        {/* <div className="progress"></div> */}

        {/* <Overlay/> */}
        <Navbar />

        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/#about" />
          <Route path="/#projects" />
          <Route path="/#contact" /> */}
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </React.Fragment>
    </div>
  );
}

export default App;
