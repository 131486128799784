import React, { useEffect, useState } from "react";

function Overlay() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (e.g., 2 seconds)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="overlay">
      <div>
        {isLoading ? <div className="bg-main-blue overalyb1"></div> : null}
        {isLoading ? <div className="bg-main-blue overalyb1"></div> : null}
        <h1 className="name absolute text-main-black text-[35px] fade-out">
          Shubh Kakadia
        </h1>
      </div>
    </div>
  );
}

export default Overlay;
