import axios from "axios";
import { USER_IP } from "../types";
import { storeNewVisistor } from "./storeip";

export const getip = (data) => (dispatch) => {
  dispatch(load());

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "http://ip-api.com/json/",
    headers: {},
  };

  axios
    .request(config)
    .then((response) => {
      console.log(response.data);
      dispatch(success(response.data.ip));
      storeNewVisistor(response.data);
    })
    .catch((error) => {
      console.log(error);
      dispatch(error(error));
    });
};

export const load = () => ({
  type: USER_IP.load,
});

export const success = (data) => ({
  type: USER_IP.success,
  payload: data,
});

export const error = (error) => ({
  type: USER_IP.error,
  payload: error,
});
