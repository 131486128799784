import "./style.css";
import shubhImg from "../assets/photo-removebg-preview.png";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Typewriter from "./Typewriter";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";

export default function Landing() {
  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/shubh-kakadia/");
  };

  const openGitHub = () => {
    window.open("https://github.com/shubhkakadia");
  };

  return (
    <div id="home">
      <script
        src="https://kit.fontawesome.com/59166d771b.js"
        crossOrigin="anonymous"
      ></script>
      <div className="bg-main-black container">
        <div className="block-1 flex justify-center items-center">
          <div>
            <div className="text-[30px] justify-center items-center md:text-[50px] text-white flex gap-3">
              <h2 className="font-protest">Hello</h2>
              <h2 className="text-main-blue font-Raleway">I'm </h2>
            </div>

            <Typewriter />
            <div className="flex justify-center text-main-blue text-[20px] liner">
              Code, design, launch – the full-stack developer's journey.
            </div>
            <div className="flex justify-center pt-10">
              <FaLinkedin
                onClick={openLinkedIn}
                title="LinkedIn Profile"
                className="text-main-blue text-[35px] mr-5 ml-5 icon"
              ></FaLinkedin>
              <FaGithub
                onClick={openGitHub}
                title="GitHub Profile"
                className="text-main-blue text-[35px] mr-5 ml-5 icon"
              ></FaGithub>
            </div>
          </div>
        </div>
        <div className="block-2 flex justify-center items-center">
          <div className="pic">
            <img
              src={shubhImg}
              alt="shubh kakadia"
              className="rounded-full "
              width="500"
            />
          </div>
        </div>
      </div>
      <About />
      <Projects />
      <Contact />
    </div>
  );
}
