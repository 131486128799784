import axios from "axios";
// import { STORE_VISITOR } from "../types";

export const storeNewVisistor = (data) => {
  //   dispatch(load());
  var config = {
    method: "post",
    url: "http://localhost:5000/user/create",
    headers: {},
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(response.data);
      // dispatch(success(response.data.response));
    })
    .catch(function (error) {
      console.log(error);
      // dispatch(error(error));
    });
};
